<template>
  <div
    v-if="label"
    class="region-cell"
    @click.stop="select"
  >
    <span class="region-cell__label">
      {{ label }}
    </span>
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const sportId = computed(() => props.event?.sport?.sportId);
    const regionId = computed(() => props.event?.region?.regionId);
    const label = computed(() => props.event?.region?.regionName);

    const select = () => {
      const query = cloneDeep(route.query);
      delete query.sport;
      delete query.region;
      delete query.competition;

      router.push({
        name: route.name,
        query: {
          ...query,
          sport: sportId.value,
          region: regionId.value,
        },
      });
    };

    return {
      label,
      select,
    };
  },
};
</script>

<style lang="scss" scoped>
.region-cell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  &:hover .region-cell__label {
    text-decoration: underline;
  }

  &.region-cell__label {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
