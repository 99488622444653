<template>
  <Label
    v-if="sport"
    :icon="sport.sportId"
  >
    {{ sport.sportName }}
  </Label>
</template>

<script>
import { computed } from 'vue';
import Label from '@/components/common/Label';

export default {
  components: {
    Label,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const sport = computed(() => props.event.sport);

    return {
      sport,
    };
  },
};
</script>
