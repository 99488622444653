<template>
  <Tooltip
    :text="event.booked ? 'Booked' : 'Not booked'"
  >
    <div class="booking-cell--root">
      <Icon :name="event.booked ? 'boolean-true' : 'boolean-false'" />
    </div>
  </Tooltip>
</template>

<script>
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Tooltip,
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.event-table .table-body .table-cell.table-cell--booking {
  .booking-cell--root {
    display: flex;
    align-items: center;
    overflow: hidden;

    .icon {
      width: 18px;
      height: 18px;
    }

    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
