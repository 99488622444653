<template>
  <Table
    ref="eventTableElement"
    class="event-table"
    :class="{ 'filters-inactive': !isEventsFiltersActive }"
    :columns="columns"
    :rows="events"
    :selected-rows="selectedEvents"
    :loading="loading"
    @row-clicked="loadEvent"
    @row-selected="selectEvent"
    @row-unselected="unselectEvent"
    @row-context-menu="showContextMenu"
    @scroll="hideContextMenu"
    selectable
  >
    <template #cell="{ column, row }">
      <EventTableCell
        :column-key="column.key"
        :event="row"
      />
    </template>
  </Table>
  <EventTableContextMenu
    ref="contextMenuElement"
    v-if="contextMenuState.visible"
    :event="contextMenuState.event"
    :top="contextMenuSafeTop"
    :left="contextMenuSafeLeft"
    @hide="hideContextMenu"
    @replay="showEventReplay"
  />
  <ReplayManager
    v-if="eventReplayState.visible"
    :event-id="eventReplayState.event.eventId"
    :event-name="eventReplayState.event.eventName"
    :event-type="eventReplayState.event.eventType"
    :is-usa-view="eventReplayState.event.isUsaView"
    @close="hideEventReplay"
  />
</template>

<script>
import {
  find,
  reduce,
  filter as filterArray,
  map,
  clamp,
} from 'lodash';
import {
  ref,
  reactive,
  computed,
  provide,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import Table from '@/components/common/Table';
import EventTableCell from './EventTableCell';
import EventTableContextMenu from './EventTableContextMenu';
import ReplayManager from './ReplayManager';

export default {
  components: {
    Table,
    EventTableCell,
    EventTableContextMenu,
    ReplayManager,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      eventsTotalCount: 0,
    });
    const columns = computed(() => store.getters.columns);
    const isEventsFiltersActive = computed(() => store.getters.isEventsFiltersActive);

    const route = useRoute();
    const routeName = computed(() => route.name);

    const events = computed(() => map(store.getters.eventList, (event) => ({
      ...event,
      // selectable: event.eventType === 'ORIGINAL', // Events that are not replayed
      selectable: true,
    })));
    const selectedEvents = computed(() => reduce(
      store.getters.selectedEvents,
      (selectedEventsOnPage, { eventId }) => {
        const event = find(events.value, { eventId });
        if (!event) return selectedEventsOnPage;
        return [...selectedEventsOnPage, event];
      },
      [],
    ));
    const selectEvent = (row) => {
      store.dispatch('updateSelectedEvents', [
        ...store.getters.selectedEvents,
        {
          eventId: row.eventId, sportId: row.sportId, booked: row.booked, isSuspended: row.isSuspended, matchState: row.matchState,
        },
      ]);
    };
    const unselectEvent = (row) => {
      store.dispatch('updateSelectedEvents', filterArray(
        store.getters.selectedEvents,
        ({ eventId }) => row.eventId !== eventId,
      ));
    };
    const loading = computed(() => store.getters.eventListLoading);

    const router = useRouter();
    const loadEvent = (row) => {
      if (route.name === 'events_full_offer') return;
      const { event, ...query } = route.query;
      router.push({
        name: route.name,
        query: {
          ...query,
          event: row.eventId,
        },
      });
    };

    const eventTableElement = ref(null);
    const eventTableBounds = computed(() => {
      const bounds = eventTableElement.value?.$el?.getBoundingClientRect();
      return bounds || {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
      };
    });
    const contextMenuElement = ref(null);
    const contextMenuBounds = computed(() => {
      const bounds = contextMenuElement.value?.$el?.getBoundingClientRect();
      return bounds || {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: 0,
        height: 0,
      };
    });
    const contextMenuState = reactive({
      top: 0,
      left: 0,
      event: null,
      visible: false,
    });
    const contextMenuSafeTop = computed(() => clamp(
      contextMenuState.top,
      eventTableBounds.value.top,
      eventTableBounds.value.bottom - contextMenuBounds.value.height,
    ));
    const contextMenuSafeLeft = computed(() => clamp(
      contextMenuState.left,
      eventTableBounds.value.left,
      eventTableBounds.value.right - contextMenuBounds.value.width,
    ));
    const showContextMenu = (data) => {
      if (route.name === 'events_full_offer') return;
      contextMenuState.top = data.top;
      contextMenuState.left = data.left;
      contextMenuState.event = data.row;
      contextMenuState.visible = true;
    };
    const hideContextMenu = () => {
      contextMenuState.top = 0;
      contextMenuState.left = 0;
      contextMenuState.event = null;
      contextMenuState.visible = false;
    };
    onClickOutside(contextMenuElement, hideContextMenu);

    const eventReplayState = reactive({
      visible: false,
      event: null,
    });
    const showEventReplay = (event) => {
      eventReplayState.event = event;
      eventReplayState.visible = true;
    };
    const hideEventReplay = () => {
      eventReplayState.event = null;
      eventReplayState.visible = false;
    };

    provide('showContextMenu', showContextMenu);

    watch(() => route.name, (newRouteName) => {
      if (newRouteName !== 'events_full_offer') return;
      hideContextMenu();
    });

    return {
      routeName,
      state,
      columns,
      events,
      selectedEvents,
      selectEvent,
      unselectEvent,
      loading,
      loadEvent,
      eventTableElement,
      contextMenuElement,
      contextMenuState,
      contextMenuSafeTop,
      contextMenuSafeLeft,
      showContextMenu,
      hideContextMenu,
      eventReplayState,
      showEventReplay,
      hideEventReplay,
      isEventsFiltersActive,
    };
  },
};
</script>

<style lang="scss">
.event-table {
  height: $eventTableHeight;

  &.filters-inactive {
    height: $eventTableHeightWithoutFilters;
  }

  .table-body .table-row {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $gray300;
    }
  }

  .table-cell-header.table-cell--actions {
    opacity: 1;
  }

  .table-head {
    .table-cell--price span,
    .table-cell--approved span,
    .table-cell--mapped span,
    .table-cell--booking span {
      text-align: center;
    }
  }
  .table-body {
    .table-cell--price,
    .table-cell--approved,
    .table-cell--mapped,
    .table-cell--booking
    {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
