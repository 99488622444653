<template>
  <div class="game-cell--root">
    <Icon
      v-if="isReplay"
      name="repeat-fourth"
      class="repeat-icon"
    />
    <div
      v-if="isTest"
      title="Test Event"
      class="test-indicator"
    >
      T
    </div>
    <div class="game-cell__content">
      <span class="game-cell__event-name">
        {{ gameLabel }}
      </span>
      <span
        v-if="event.manuallyCreated"
        class="game-cell__manual-event"
      >
        Manually created
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { join, reverse, split } from 'lodash';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const gameLabel = computed(() => (props.event.isUsaView
      ? join(reverse(split(props.event.eventName, ' v ')), ' @ ')
      : props.event.eventName));
    const isReplay = computed(() => props.event.eventType === 'REPLAY');
    const isTest = computed(() => props.event.eventType === 'TEST');

    return {
      gameLabel,
      isReplay,
      isTest,
    };
  },
};
</script>

<style lang="scss">
  .game-cell--root {
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 10px;

    .test-indicator {
      height: 16px;
      width: 16px;
      background-color: #30accf;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      line-height: 10px;
    }

    .game-cell__content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
    }

    .game-cell__event-name {
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .game-cell__manual-event {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      color: #6D6D6D;
    }
  }
</style>
