<template>
  <div
    v-if="dateTime.length"
    class="startsAt-text"
  >
    {{ dateTime[0] }}

    <span>
      &bull;
      {{ dateTime[1] }}
    </span>
  </div>

  <div v-else>
    -
  </div>
</template>

<script>
import { computed } from 'vue';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { split, replace, filter } from 'lodash';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const dateTime = computed(() => {
      const { startsAt } = props.event;
      const formattedStartsAt = replace(
        startsAt ? format(zonedTimeToUtc(startsAt, 'UTC'), 'MMM d, yyyy @ hh:mm a') : '@',
        `, ${new Date().getFullYear()}`,
        '',
      );
      return filter(split(formattedStartsAt, '@'), Boolean);
    });

    return {
      dateTime,
    };
  },
};
</script>

<style lang="scss">
.event-table .table-cell.table-cell--startsAt {
  overflow: hidden;
  & .startsAt-text {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    color: #A9A9A9;
  }
}
</style>
