<template>
  <div class="features-cell">
    <span v-if="!bookedKeys.length">
      -
    </span>
    <Tooltip
      v-for="key in bookedKeys"
      :key="key"
      :text="getTooltip(key)"
      left
    >
      <Chip>
        {{ getLabel(key) }}
      </Chip>
    </Tooltip>
  </div>
</template>

<script>
import { computed } from 'vue';
import { keys, pickBy, identity } from 'lodash';
import Tooltip from '@/components/common/Tooltip';
import Chip from '@/components/common/Chip';

const labelsByKey = {
  core: 'C&D',
  sameGameParlays: 'SGP',
  playerProps: 'PP',
  microMarkets: 'MM',
};

const tooltipsByKey = {
  core: 'Core and Derivative markets',
  sameGameParlays: 'Same Game Parlay',
  playerProps: 'Player Props markets',
  microMarkets: 'Micro Markets',
};

export default {
  components: {
    Tooltip,
    Chip,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const bookedMap = computed(() => pickBy({
      core: props.event.booked,
      sameGameParlays: props.event.booked && props.event.sameGameParlaysBooked,
      playerProps: props.event.booked && props.event.playerPropsBooked,
      microMarkets: props.event.booked && props.event.microMarketsBooked,
    }, identity));
    const bookedKeys = computed(() => keys(bookedMap.value));

    const getLabel = (key) => labelsByKey[key];
    const getTooltip = (key) => tooltipsByKey[key];

    return {
      bookedKeys,
      getLabel,
      getTooltip,
    };
  },
};
</script>

<style lang="scss">
.features-cell {
  display: flex;
}
</style>
