<template>
  <Tooltip
    :text="hasInternalPrices ? 'Has pricing' : 'Has no pricing'"
  >
    <div
      :class="[
        'price-cell--root',
      ]"
    >
      <Icon :name="hasInternalPrices ? 'boolean-true' : 'boolean-false'" />
    </div>
  </Tooltip>
</template>

<script>
import { computed } from 'vue';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hasInternalPrices = computed(() => props.event.hasInternalPrices);

    return {
      hasInternalPrices,
    };
  },
};
</script>

<style lang="scss">
.event-table .table-body .table-cell.table-cell--price {
  .price-cell--root {
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 4px;

    .icon {
      width: 18px;
      height: 18px;
    }

    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
