<template>
  <div
    v-if="loading"
    class="template-cell template-cell--loading"
    @click.stop="() => {}"
  >
    <Spinner
      small
    />
    Opening...
  </div>
  <div
    v-else
    class="template-cell"
    @click.stop="goToSportTemplate"
  >
    <Icon :name="'external'" />
    <span class="template-text">Template</span>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { fetchAppliedTemplates } from '@/services/api';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';

const hasAppliedTemplate = (appliedTemplates) => appliedTemplates?.nodes?.length && appliedTemplates?.nodes?.[0];

export default {
  components: {
    Icon,
    Spinner,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const loading = ref(false);

    const goToSportTemplate = async () => {
      try {
        loading.value = true;
        const appliedTemplates = await fetchAppliedTemplates(props.event.eventId);

        if (!hasAppliedTemplate(appliedTemplates)) {
          store.dispatch('addNotification', {
            message: 'No sport template for the event.',
            type: 'success',
            duration: 3000,
          });
          return;
        }

        const { href } = router.resolve({
          name: 'sport-template',
          params: {
            id: appliedTemplates.nodes[0].sportTemplate.sportTemplateId,
          },
          query: { ...router.currentRoute.value.query },
        });
        window.open(href, '_blank');
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      goToSportTemplate,
    };
  },
};
</script>

<style lang="scss">
.template-cell {
    display: flex;
    align-items: center;
    overflow: hidden;

    & .icon {
        stroke: $black;
        margin-right: 10px;
    }

    &:not(.no-template):hover .template-text {
        text-decoration: underline;
    }

    &.no-template {
      color: $gray600;
    }

    &.no-template .icon {
      stroke: $gray600;
    }

    & .template-text {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.template-cell--loading {
      gap: 0.5rem;
    }

}
</style>
