<template>
  <div
    class="highlighted-cell"
  >
    <span class="highlighted-cell__label">
      {{ label }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const label = computed(() => (props.event.isHighlighted ? 'Yes' : 'No'));
    return {
      label,
    };
  },
};
</script>

<style lang="scss" scoped>
.highlighted-cell {
  overflow: hidden;

  & .highlighted-cell__label {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
