<template>
  <Chip :variant="variant">
    {{ label }}
  </Chip>
</template>

<script>
import { computed } from 'vue';
import Chip from '@/components/common/Chip';

export default {
  components: {
    Chip,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const variant = computed(() => {
      switch (props.event?.matchState) {
      case 'PREMATCH':
        return 'default';
      case 'LIVE':
        return 'green';
      case 'FINISHED':
        return 'blue';
      case 'CANCELED':
        return 'blue';
      default:
        return 'white';
      }
    });
    const label = computed(() => {
      switch (props.event?.matchState) {
      case 'PREMATCH':
        return 'Pregame';
      case 'LIVE':
        return 'Live';
      case 'FINISHED':
        return 'Finished';
      case 'CANCELED':
        return 'Canceled';
      default:
        return 'N/A';
      }
    });

    return {
      variant,
      label,
    };
  },
};
</script>
<style lang="scss">
.event-table .table-body .table-cell.table-cell--matchState {
  overflow: hidden;
}
</style>
