<template>
  <span
    class="fallback-cell"
    v-html="value"
  />
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = computed(() => {
      switch (props.columnKey) {
      case 'competition':
        return props.event?.competition?.competitionName || '-';
      case 'region':
        return props.event?.region?.regionName || '-';
      default:
        return props.event?.[props.columnKey] || '-';
      }
    });

    return {
      value,
    };
  },
};
</script>
<style lang="scss">
  .fallback-cell {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
